<template>
  <div class="tokyo_tm_about">
    <div class="about_image">
      <img src="../assets/img/slider/1.jpg" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name">Toaster Artist</h3>
      <div class="description_inner">
        <div class="left">
          <p>
            Hi! I create art, journals and software. I'm based in
             Texas. Come checkout my art, books, and merch.
          </p>
          <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Read More</button>
          </div>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p>
                <span>Books:</span
                ><a href="https://www.amazon.com/s?i=stripbooks&rh=p_27%3AToaster+Artist&s=relevancerank&text=Toaster+Artist&ref=dp_byline_sr_book_1">Toaster Artist Publishing</a>
              </p>
            </li>
            <li>
              <p>
                <span>Email:</span
                ><a href="">toasterartist@gmail.com</a>
              </p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">

              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>270+</h3>
                      <span>Published Books</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>9.5K+</h3>
                      <span>Pinterest Followers</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>2.2K+</h3>
                      <span>TikTok Followers</span>
                    </div>
                  </li>
                </ul>
                <!-- End ul -->
              </div>
              <!-- End .counter -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</template>

<script>
let ROOT_PATH = 'https://www.toasterartist.com'

export default {
  metaInfo() {
    return {
      title: 'Toaster Artist | About',
      meta: [
          // Twitter Card
          {name: 'twitter:card', content: 'summary'},
          {name: 'twitter:title', content: 'Toaster Artist | About'},
          {name: 'twitter:description', content: 'Art, Books, Software Development'},
          // image must be an absolute path
          {name: 'twitter:image', content: this.metaImage},
          // Facebook OpenGraph
          {property: 'og:title', content: 'Toaster Artist | About'},
          {property: 'og:site_name', content: 'Toaster Artist'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content:  this.metaImage},
          {property: 'og:description', content: 'Art, Books, Software Development'},
      ]
    }
  },
  components: {
  },
  data() {
    return {
      metaImage: ROOT_PATH + require('../assets/img/logo/logo.png'),
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
